import { useState } from 'react';
import { Spinner } from 'react-spinner-loader';



export default function Loader() {
    
  return (
    <div className='loader'>

    </div>
  )
}
